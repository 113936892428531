<template>
  <div class="level_index level_indexI">
    <div class="search">
      <el-form :inline="true" :model="search">
        <el-form-item prop="title">
          <el-input
            clearable
            @keydown.enter.native="searchList"
            accesskey=""
            v-model="searchtitle"
            placeholder="请输入组织机构名称"
          />
        </el-form-item>

        <el-form-item v-show="ISSenior">
          <el-date-picker style="width: 145px" v-model="startTime" type="date" placeholder="会员开始时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item v-show="ISSenior">
          <el-date-picker style="width: 145px" v-model="endTime" type="date" placeholder="会员到期时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="是否到期" v-show="ISSenior">
          <el-switch
            :active-value="1"
            active-color="#5BD995"
            active-text="是"
            :inactive-value="2"
            inactive-color="#D9D9D9"
            inactive-text="否"
            class="switchSelfBox"
            style="margin-top: 5px"
          >
          </el-switch>
        </el-form-item>

        <el-form-item v-show="ISSenior">
          <el-select clearable filterable placeholder="请选择排序方式" style="width: 145px">
            <!-- <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option> -->

            <el-option :label="'升序'" :value="'1'" :key="'1'"></el-option>
            <el-option :label="'降序'" :value="'2'" :key="'2'"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-show="ISSenior">
          <el-select clearable filterable placeholder="请选择机构类型" style="width: 145px">
            <!-- <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option> -->

            <el-option :label="'公司'" :value="'1'" :key="'1'"></el-option>
            <el-option :label="'部门'" :value="'2'" :key="'2'"></el-option>
          </el-select>
        </el-form-item>

        <el-button type="primary" @click="searchList()">
          <i class="fa fa-search" aria-hidden="true"></i>
          查询
        </el-button>

        <!-- <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:dep:addVIP')">
            <i class="fa fa-plus" aria-hidden="true"></i>
            添加机构
          </el-button> -->

        <!-- <el-button class="block" @click="del">批量删除</el-button> -->

        <el-button
          v-show="expandKeys"
          v-if="getPurviewNew('sys:dep:expandVIP')"
          type="primary"
          @click="toggleRowExpansion(true)"
        >
          全部展开
        </el-button>

        <el-button
          v-show="!expandKeys"
          v-if="getPurviewNew('sys:dep:collapseVIP')"
          type="danger"
          @click="toggleRowExpansion(false)"
        >
          全部折叠
        </el-button>

        <el-form-item style="position: absolute; right: 0; top: 0; margin: 0">
          <el-button v-show="!ISSenior" type="primary" @click="ISSenior = !ISSenior" style="margin: 0">
            高级搜索
          </el-button>
          <el-button v-show="ISSenior" type="danger" @click="ISSenior = !ISSenior" style="margin: 0">
            高级搜索
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="el-tableII FanfuTableBox">
      <div class="headView">
        <span>单位名称</span>
        <span>类型</span>
        <span>状态</span>
        <span>排序</span>
        <span>创建人</span>
        <span>会员开通时间</span>
        <span>会员到期时间</span>
        <span>会员是否到期</span>
        <!-- <span>创建时间</span>
        <span>更新时间</span> -->
        <span>操作</span>
      </div>

      <el-tree
        class="headViewtree"
        :data="data.list"
        stripe
        border
        ref="multipleTable"
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :props="defaultProps"
        @node-click="handleNodeClick"
        node-key="id"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>

          <span>
            <el-button type="primary" round v-if="data.type == 1" size="mini">公司</el-button>
            <el-button type="success" round v-if="data.type == 2" size="mini">部门</el-button>
          </span>

          <span>
            <el-switch
              v-model="data.status"
              :active-value="1"
              active-color="#5BD995"
              active-text="正常"
              :inactive-value="2"
              inactive-color="#D9D9D9"
              inactive-text="禁用"
              class="switchSelfBox"
              style="pointer-events: painted"
              @change="switchStatus(data)"
            >
            </el-switch>
          </span>

          <span>{{ data.sort }}</span>

          <span>{{ data.create_name }}</span>

          <span>{{ data.term_start_time }}</span>
          <span>{{ data.term_end_time }}</span>

          <!-- <span>{{ timestampToTime(data.create_time, 2) }}</span>
          <span>{{ timestampToTime(data.update_time, 2) }}</span> -->

          <span> 否 </span>

          <span>
            <div class="operate">
              <!-- <el-button
                @click.native.stop="toSearchChild(data)"
                v-if="getPurviewNew('sys:dep:addzVIP') && data.has_child == 1"
              >
                添加
              </el-button> -->

              <el-button type="primary" @click.native.stop="edit(data)" v-if="getPurviewNew('sys:dep:editVIP')">
                编辑
              </el-button>
              <!-- <el-button type="danger" @click.native.stop="del(data)" v-if="getPurviewNew('sys:dep:dropVIP')">
                删除
              </el-button> -->
            </div>
          </span>

          <!-- <span class="custom_table_cell"> </span> -->
        </div>
      </el-tree>
    </div>

    <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />
  </div>
</template>

<script>
import Search from './components/HospitalSearch'

import resizeDetector from 'element-resize-detector'

export default {
  components: {
    Search
  },
  data() {
    return {
      search: {
        title: '',
        name2: '',
        name3: '',
        name4: '',
        name5: '',
        name6: ''
      },

      ISSenior: false,

      loading: false,

      select: [],

      data: {
        list: [],
        page: 1,
        size: 15,
        total: 0
      },

      searchtitle: '',

      startTime: '',
      endTime: '',

      expandKeys: true,

      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },

  mounted() {
    this.modifyLayout()
  },

  created() {
    this.modifyLayout()

    this.getList()
  },

  watch: {
    searchtitle(newValue, oldValue) {
      if (oldValue.length > 0 && newValue.length == 0) {
        this.searchList()
      }
    }
  },

  methods: {
    handleNodeClick(data) {
      console.log(data)
    },

    toggleRowExpansion(val) {
      this.expandKeys = !this.expandKeys

      const tree = this.$refs.multipleTable.store.nodesMap

      for (let key in tree) {
        tree[key].expanded = !this.expandKeys
      }
    },

    expandChange(row, expandRow) {
      console.log(row, expandRow)
    },

    // toggleRowExpansion(val) {
    //   this.expandKeys = !this.expandKeys
    //   this.formatTree(this.data.list, 0)
    // },

    formatTree(tree, val, val1) {
      for (const node of tree) {
        if (this.expandKeys) {
          this.$refs.multipleTable.toggleRowExpansion(node, false)
        } else {
          this.$refs.multipleTable.toggleRowExpansion(node, true)
        }

        if (node.children && node.children.length) {
          this.formatTree(node.children, val + 1)
        }
      }
    },

    expandChange(row, expandRow) {
      console.log(row, expandRow)
    },

    // AllTotal() {
    //     let a = list.length;
    //     for (let i = 0; i < list.length; i++) {
    //         var dic = list[i];
    //         if (dic.hasOwnProperty("children")) {
    //             a = a + dic.children.length
    //         }
    //     }
    //     return a;
    // },

    // getList(size) {
    //     if (size) {
    //         this.data.page = 1
    //         this.data.size = size
    //     }
    //     this.loading = true
    //     this.select = []
    //     this.$nextTick(() => {
    //         const start = this.data.size * (this.data.page - 1)
    //         const end = start + this.data.size
    //         this.data.total = list.length
    //         const data = []
    //         for (let i = start; i < end; i++) {
    //             if (list[i]) {
    //                 data.push(list[i])
    //             }
    //         }
    //         this.data.list = data
    //         setTimeout(() => {
    //             this.loading = false
    //         }, 1000)
    //         setTimeout(() => {
    //             this.modifyLayout();
    //         }, 1100)
    //     })
    // },

    getList() {
      //
      this.$http
        .get('/api/dep/tree', {
          name: this.searchtitle,

          term_start_time: this.startTime,
          term_end_time: this.endTime

          // page_size: this.data.size,
          // page: this.data.page
        })
        .then((res) => {
          console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

          if (res.code == 200) {
            this.data.list = res.data
            // this.data.total = res.data.total

            setTimeout(() => {
              this.modifyLayout()
            }, 1100)
          }

          if (res.code != 200) {
            //请求错误
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    searchList() {
      this.getList()
    },

    view(row) {
      console.log(row)
    },

    edit(row) {
      console.log(row)

      this.$refs.searchRef.edit(row)
    },

    switchStatus(row) {
      // console.log(row);

      // return

      let a = row.status == 1 ? 2 : 1

      this.$http
        .put('/api/dep/setStatus/' + row.id, {
          status: row.status
        })
        .then((res) => {
          // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
          if (res.code != 200) {
            //请求错误
            row.status = a
            this.$message.error(res.msg)
          }
          if (res.code == 200) {
            this.$message.success(res.msg)
            // this.close()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    del(row) {
      console.log(row)

      const h = this.$createElement
      this.$msgbox({
        title: '删除',
        message: h('p', null, [h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！')]),
        showCancelButton: true,
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'

            // setTimeout(() => {
            //     done();
            //     setTimeout(() => {
            //         instance.confirmButtonLoading = false;
            //     }, 300);
            // }, 3000);

            this.$http
              .del('/api/dep/' + row.id)
              .then((res) => {
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

                if (res.code == 200) {
                  this.getList()
                }
                if (res.code != 200) {
                  //请求错误
                  this.$message.error(res.msg)
                }

                // setTimeout(() => {
                done()
                setTimeout(() => {
                  instance.confirmButtonLoading = false
                }, 300)
                // }, 3000);
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            done()
          }
        }
      }).then((action) => {
        // this.$message({
        //     type: 'info',
        //     message: 'action: ' + action
        // });
      })
    },

    toSearch() {
      this.$refs.searchRef.open()
    },
    toSearchChild(row) {
      this.$refs.searchRef.openChild(row)
    },

    modifyLayout() {
      const erd = resizeDetector()

      erd.listenTo(document.getElementsByClassName('search'), (element) => {
        const width = element.offsetWidth
        const height = element.offsetHeight

        var a = 141 + 60 - 35 + height
        if ($('.level_indexI .FanfuTableBox')[0]) {
          $('.level_indexI .FanfuTableBox')[0].setAttribute('style', 'height: calc(100vh - ' + a + 'px) !important')
        }

        var b = 141 + 60 + 33 + height
        if ($('.level_indexI .el-table')[0]) {
          $('.level_indexI .el-table')[0].setAttribute('style', 'height: calc(100vh - ' + b + 'px) !important')
        }
      })
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)

      this.data.page = val

      this.getList()
    }
  }
}
</script>





<style lang="scss" scoped>
.el-button--success {
  color: #ffffff;
  background-color: #5bd995;
  border-color: #5bd995;
}

// .level_index.level_indexI {
//     border: 1px blue dotted;
//     box-sizing: border-box;
//     position: relative;
// }
.search {
  // border: 1px blue dotted;
  box-sizing: border-box;

  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;

  padding-right: 100px;

  line-height: 40px;

  /deep/ .el-form-item__label {
    // border: 1px blue dotted;
    // box-sizing: border-box;

    line-height: 30px;
  }
}
</style>




<style lang="scss" scoped>
.el-button--success {
  color: #ffffff;
  background-color: #5bd995;
  border-color: #5bd995;
}
</style>


<style scoped lang="scss">
.headView {
  //   border: 1px #f00 solid;
  border-bottom: 1px #dfdfdf solid;
  box-sizing: border-box;
  height: 36px;
  z-index: 99 !important;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  z-index: 999 !important;
  // display: block;

  min-width: 1450px;
  background-color: #fff !important;

  span {
    line-height: 36px;

    font-weight: bold;
    color: #656565;
    font-size: 12px;

    display: inline-block;
    // border-left: 1px #dfdfdf solid;
    // box-sizing: border-box;
    text-align: center;
    position: relative;

    // border: 1px green solid;
    // box-sizing: border-box;
  }

  span::after {
    content: '';
    border-right: 1px #dfdfdf solid;
    box-sizing: border-box;
    width: 0px;
    height: calc(100vh - 220px);

    position: absolute;
    right: 0;
  }

  span:last-of-type::after {
    border: none;
  }

  span:nth-of-type(1) {
    width: 460px;
    border: none;
  }
  span:last-of-type {
    min-width: 100px !important;
  }
}

/deep/.el-tree-node__content {
  // border: 1px green solid;
  border-bottom: 1px #dfdfdf solid;
  box-sizing: border-box;

  height: 40px;
  line-height: 40px;

  overflow: visible;

  position: relative;
}
/deep/.el-tree-node__content::before {
  content: '.';
  color: transparent;
}

/deep/.el-tree-node__content > .el-tree-node__expand-icon {
  // padding: 6px;
  padding: 0;
}

.custom-tree-node {
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: 60px;
  height: 40px;

  span,
  button {
    font-size: 12px;
  }

  button {
    height: 30px;
  }

  span:nth-of-type(1) {
    line-height: 36px;
    margin-right: 10px;
    // display: inline;
    width: 400px;

    display: inline-block;

    // border: 1px red solid;
    // box-sizing: border-box;
  }
}

.headView,
.custom-tree-node {
  span {
    // min-width: 80px;
    display: inline-block;
    // padding-left: 10px;
    // border: 1px green solid;
  }

  span:nth-of-type(2) {
    width: 80px;
  }

  span:nth-of-type(3) {
    width: 80px;
  }
  span:nth-of-type(4) {
    width: 80px;
  }

  span:nth-of-type(5) {
    width: 200px;
  }

  span:nth-of-type(6) {
    width: 140px;
  }

  span:nth-of-type(7) {
    width: 140px;
  }

  span:nth-of-type(8) {
    width: 100px;
  }

  // span:nth-of-type(8) {
  //   width: 140px;
  // }

  // span:nth-of-type(9) {
  //   width: 140px;
  // }

  span:last-of-type {
    min-width: 100px;
  }

  // span:nth-of-type(8) {
  //   width: 240px;
  //   border: none;
  //   // border: 1px green solid;
  // }
}

.headViewtree {
  //   border: 1px #f00 solid;
  box-sizing: border-box;
  min-width: 1450px;
}

// .el-tableII
.FanfuTableBox {
  border: 1px #dfdfdf solid;
  box-sizing: border-box;

  overflow-y: scroll;
  overflow-x: scroll;
  padding: 0px 0px 0px 0px;

  position: relative;

  // border: 1px #00f solid;
  height: calc(100vh - 210px);

  .layui-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    // border: 1px red solid;
    box-sizing: border-box;

    // margin-top: 5px;
    position: relative;
    top: 5px;
    left: -6px;
  }

  b:nth-of-type(1) {
    width: 300px;
    height: 20px;
    display: inline-block;
    // border: 1px red solid;
    box-sizing: border-box;
  }

  b:nth-of-type(2) {
    width: 200px;
    height: 20px;
    display: inline-block;
    // border: 1px red solid;
    box-sizing: border-box;
  }

  b:nth-of-type(3) {
    width: 50px;
    // height: 20px;
    // display: inline-block;
    // border: 1px red solid;
    box-sizing: border-box;
  }
}

// el-tree-node is-expanded is-current is-focusable

/deep/.el-tree-node {
  // border: 1px red solid;
  box-sizing: border-box;
  // padding: 10px 0;

  // width: auto;
  // display: inline-block;

  overflow: visible;

  position: relative;

  min-width: 1450px;

  // min-width: 1000px;
  // width: 100%;
  // display: block;
}

.operate {
  // border: 1px purple solid;
  box-sizing: border-box;
  display: inline-block;

  button {
    font-size: 12px;
    height: auto;
  }
}

/deep/.el-tree-node:nth-of-type(even) {
  background: #fafafa !important;
}
</style>

